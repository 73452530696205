<template>
  <div>
    <vs-popup fullscreen title="Listar Rastreamentos" :active.sync="popUpListarRastreamentos.exibir" v-if="popUpListarRastreamentos.exibir">
        <ListarRastreamentos :veiculo="popUpListarRastreamentos.veiculo" />
    </vs-popup>  
    <vs-popup title="Coberturas Operadoras" :active.sync="popUpCobertura.exibir" v-if="popUpCobertura.exibir">
        <CoberturaOperadoras :parametros="popUpCobertura" v-if="popUpCobertura.exibir" />       
    </vs-popup>
    <vs-popup fullscreen title="Comandos" :active.sync="popUpEnviarComando.exibir" v-if="popUpEnviarComando.exibir">
        <EnviarComando :veiculo="popUpEnviarComando.veiculo" />
    </vs-popup>  
    <vs-popup :title="`Lista Acessórios - ${popUpAcessorios.veiculo.placa}`" :active.sync="popUpAcessorios.exibir" v-if="popUpAcessorios.exibir">
       <vs-table :data="listaAcessorios" class="mt-2" noDataText="Nenhum acessório" stripe hoverFlat >
          <template slot="thead">
              <vs-th class="p-0 text-center">Marca</vs-th>
              <vs-th class="p-0 text-center">Tipo</vs-th>            
              <vs-th class="p-0 text-center">Modelo</vs-th>            
              <vs-th class="p-0 text-center">Serial</vs-th>          
              <vs-th class="p-0 text-center">Valor Mensal</vs-th> 
              <vs-th >Local Instalação</vs-th>                            
          </template>
          <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">                
                <vs-td class="p-0"><small style='font-family: Verdana, sans-serif;'>{{tr.marca}}</small></vs-td>                
                <vs-td class="p-0"><small style='font-family: Verdana, sans-serif;'>{{tr.tipo}}</small></vs-td>  
                <vs-td class="p-0"><small style='font-family: Verdana, sans-serif;'>{{tr.modelo}}</small></vs-td>  
                <vs-td class="p-0 text-center"><small style='font-family: Verdana, sans-serif;'>{{tr.serial}}</small></vs-td>  
                <vs-td class="p-0 text-center"><small style='font-family: Verdana, sans-serif;'>{{tr.valorIncrementoNaMensalidadeFormatado}}</small></vs-td>                 
                <vs-td class="p-0 text-center"><small style='font-family: Verdana, sans-serif;'>{{tr.localInstalacaoNoVeiculo}}</small></vs-td>                 
              </vs-tr>
          </template>
       </vs-table>     
    </vs-popup> 
    <vs-popup :title="`${popUpHistoricoEventos.titulo} - ${popUpHistoricoEventos.veiculo.placa}`" :active.sync="popUpHistoricoEventos.exibir" v-if="popUpHistoricoEventos.veiculo">
       <div class="flex">
          <div class="flex w-full items-center text-center">
            <vs-radio v-model="popUpHistoricoEventos.pesquisarModuloBackup" :vs-value="false" v-if="popUpHistoricoEventos.possuiModuloBackup"><small >Módulo Principal <br/><span class="font-bold">{{popUpHistoricoEventos.veiculo.codigoModulo}}</span></small></vs-radio>
            <vs-radio v-model="popUpHistoricoEventos.pesquisarModuloBackup" :vs-value="true" v-if="popUpHistoricoEventos.possuiModuloBackup" class="ml-4"><small>Módulo Backup <br/><span class="font-bold">{{popUpHistoricoEventos.veiculo.codigoModuloBkp}}</span></small></vs-radio>
          </div>

          <flat-pickr v-model="popUpHistoricoEventos.dataHoraPanico" :config="configDatePicker" placeholder="Data"/>
          <vs-button class="ml-2 mr-2" icon="close" color="primary" @click="popUpHistoricoEventos.dataHoraPanico = null"/>          
          <vs-button class="ml-2" icon="search" color="primary" @click="fetchHistorico()"/>
          <vs-button class="ml-2" icon="print" color="primary" v-if="false"></vs-button> 
       </div>
       <vs-table :data="dadosDaGridHistorico" class="tabelaComScroll mt-2" pagination noDataText="Nenhum evento encontrado" max-items="10">
          <template slot="thead">
              <vs-th>Evento</vs-th>
              <vs-th>Data</vs-th>            
              <vs-th v-if="popUpHistoricoEventos.tipoHistorico === 'ERROS'">Descrição</vs-th>            
          </template>
          <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data" >
                <vs-td><small>{{tr.evento}}</small></vs-td>                
                <vs-td><small>{{tr.dataHora}}</small> </vs-td>
                <vs-td v-if="popUpHistoricoEventos.tipoHistorico === 'ERROS'"><small>{{tr.descricaoComplementar}}</small> </vs-td>
              </vs-tr>
          </template>
       </vs-table>
    </vs-popup>    
    <vs-popup :title="`Log de Comunicação - ${popUpLogComunicacao.veiculo.placa}`" :active.sync="popUpLogComunicacao.exibir" v-if="popUpLogComunicacao.veiculo">
         <div class="flex">
          <flat-pickr v-model="popUpLogComunicacao.dataComunicacao" :config="configDatePicker" placeholder="Data"/>
          <vs-button class="ml-2" icon="close" color="primary" @click="popUpLogComunicacao.dataComunicacao = null"/>
          <vs-button class="ml-2" icon="search" color="primary" @click="fetchLogComunicacao()" :disabled="popUpLogComunicacao.dataComunicacao === null"/>          
       </div>   
       <div class="flex mt-1">
            <feather-icon icon="ActivityIcon" svgClasses="w-5 h-5 fill-current text-success" class="mr-2" /> ONLINE
            <feather-icon icon="ActivityIcon" svgClasses="w-5 h-5 fill-current text-danger" class="ml-2 mr-2"/> OFFLINE
       </div> 
       <vue-apex-charts type="rangeBar" height="350" :options="chartOptions" :series="series"/>
        <vs-table :data="logComunicacao" class="tabela2ComScroll mt-2" noDataText="Nenhuma comunicação registrada" stripe hoverFlat >
          <template slot="thead">
              <vs-th class="p-0">Hora</vs-th>
              <vs-th class="p-0"> Status</vs-th>            
          </template>
          <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td class="p-0"><small style='font-family: Verdana, sans-serif;'>{{tr.hora}}</small></vs-td>                
                <vs-td class="p-0">
                    <feather-icon icon="ActivityIcon" svgClasses="w-5 h-5 fill-current text-success"  v-if="tr.status === 1" />
                    <feather-icon icon="ActivityIcon" svgClasses="w-5 h-5 fill-current text-danger"  v-if="tr.status === 0" />
                </vs-td>
              </vs-tr>
          </template>
       </vs-table>     
    </vs-popup> 
   
    <vs-row class="p-0">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12"> 
            <vx-card class="mr-4 p-0">
                 <label class="vs-input--label">Cliente:</label>
                 <v-select  
                    label="nome"                                                                 
                    :options="listaClientes"  
                    :filter="pesquisarCliente"
                    v-model="clienteSelecionado"      
                    @input="fetchListaVeiculos"                                  
                    class="w-full vs-input--label">
                    <template v-slot:option="option">                        
                        <span style='font-family: Verdana, sans-serif;'> {{ option.CNPJCPF }} </span> - {{ option.nome }}  - {{ option.razaoSocial }}
                    </template>
                </v-select>  
                
            </vx-card>
        </vs-col>
    </vs-row> 
    <vs-row v-if="exibirMensagemNenhumDadoEncontrado">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="6">           
            <vs-alert class="mt-4 mr-4" color="danger" title="Atenção">
                Não existem veículos ativos.
            </vs-alert>                          
        </vs-col>
    </vs-row>
    <vs-row>
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="12"> 
            <vs-table :data="listaVeiculos" class="tabelaComScroll w-full mr-4" noDataText="..." search hoverFlat stripe>
                <template slot="header">
                    <h6 class="ml-2 mt-2" v-if="listaVeiculos.length > 0 "> {{listaVeiculos.length}} veículos</h6>
                    <vx-tooltip text="Atualizar os veículos">                
                        <vs-button size="small" class="ml-4" color="primary" type="filled" v-if="listaVeiculos.length > 0 " icon-pack="feather" icon="icon-refresh-cw" @click="fetchListaVeiculos()"></vs-button>
                    </vx-tooltip>
                    <vx-tooltip text="Download do relatório">                
                        <vs-button size="small" class="ml-4" color="primary" type="filled" v-if="listaVeiculos.length > 0 " icon-pack="feather" icon="icon-download" @click="print()"></vs-button>
                    </vx-tooltip>
                     <h6 class="ml-2" v-if="listaVeiculos.length > 0 "> 
                        [ 
                        <feather-icon icon="WifiIcon" svgClasses="w-4 h-4 fill-current text-success" class="ml-2 mr-2"/> {{getQtdOnline}} online /
                        <feather-icon icon="WifiIcon" svgClasses="w-4 h-4 fill-current text-danger" class="ml-2 mr-2"/> {{listaVeiculos.length - getQtdOnline}} offline
                        ]
                    </h6>   
                    <p class="ml-4 text-sm" v-if="atualizadoAs">Pesquisado em <span class="font-medium">{{atualizadoAs}} </span></p>
                </template>
                <template slot="thead">
                    <vs-th class="p-0" sort-key="placa">VEÍCULO</vs-th>                    
                    <vs-th class="p-0" sort-key="codigoModulo"><small class="text-center w-full">CÓDIGO</small></vs-th>
                    <vs-th class="p-0" sort-key="operadoraGSM"><small class="text-center w-full">GSM</small></vs-th>
                    <vs-th class="p-0"><small class="text-center w-full">STATUS</small></vs-th>  
                    <vs-th class="p-0"><small class="text-center w-full">OPCIONAIS</small></vs-th>                      
                    <vs-th class="p-0" sort-key="dataAtivacaoEmLong"><small class="text-center w-full">ATIVAÇÃO</small></vs-th>                    
                    <vs-th class="p-0" sort-key="dataUltimoPacoteRecebidoLong"><small class="text-center w-full">COMUNICAÇÃO<br/>ATUALIZAÇÃO</small></vs-th>
                    <vs-th class="p-0"><small class="text-center w-full">GPS</small></vs-th>
                    <vs-th class="p-0"><small class="text-center w-full">ODÔMETRO</small></vs-th>
                    <vs-th class="p-0"><small class="text-center w-full">HORÍMETRO</small></vs-th>
                    <vs-th class="p-0"><small class="text-center w-full">RPM</small></vs-th>
                    <vs-th class="p-0"><small class="text-center w-full">AÇÃO</small></vs-th>
                </template>
                <template slot-scope="{data}">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data" style="border-bottom-style: solid; border-bottom-width: 1px; border-bottom-color: rgba(255, 255, 255, 0.2);">
                        <vs-td class="p-0" :data="data[indextr].placa"><small>{{tr.marca}} {{tr.modelo}} </small> 
                            <br/> <small class="font-bold"> {{tr.placa}}</small>
                            <br/> <small class="font-medium"> {{tr.labelVeiculo}}</small>
                        </vs-td>
                        <vs-td class="p-0" :data="data[indextr].codigoModulo">
                                <feather-icon icon="ChevronRightIcon" svgClasses="w-3 h-3 fill-current text-success" class="mr-1" v-if="tr.codigoModuloBkp"/>
                                <small class="font-bold">{{tr.codigoModulo}}</small> <br/> <small>{{tr.marcaModulo}}</small> - <small>{{tr.tipoModulo}}</small>
                                <br v-if="tr.codigoModuloBkp"/>
                                <feather-icon icon="ChevronRightIcon" svgClasses="w-3 h-3 fill-current text-warning" class="mr-1" v-if="tr.codigoModuloBkp"/>
                                <small class="font-bold" v-if="tr.codigoModuloBkp">{{tr.codigoModuloBkp}}</small> <br v-if="tr.codigoModuloBkp"/> <small v-if="tr.codigoModuloBkp">{{tr.marcaModuloBkp}}</small> <small v-if="tr.codigoModuloBkp"> - {{tr.tipoModuloBkp}}</small>
                        </vs-td>
                        <vs-td class="p-0" :data="data[indextr].operadoraGSM">
                            <vx-tooltip :text="'Multioperadora:' + tr.operadoraGSMConectada ">                
                                <small class="font-bold">{{tr.operadoraGSM}}</small>
                                <br/> <small>{{tr.numeroGSM}}</small>
                            </vx-tooltip>                                
                                <small class="font-bold" v-if="tr.codigoModuloBkp">{{tr.operadoraGSMBkp}}</small>
                                <br v-if="tr.codigoModuloBkp"/> <small v-if="tr.codigoModuloBkp">{{tr.numeroGSMBkp}}</small>
                            
                        </vs-td>
                        <vs-td class="p-0">
                            <div class="flex">
                                <vx-tooltip v-bind:text="'Veículo ligado  - voltagem ' + tr.ultimaVoltagem + 'v'">                
                                    <feather-icon icon="CircleIcon" svgClasses="w-3 h-3 fill-current text-success" class="ml-2"  v-if="tr.ignicao === 'LIGADO' && tr.velocidadeAtual > 0" />
                                </vx-tooltip>
                                <vx-tooltip v-bind:text="'Veículo ligado e parado - voltagem ' + tr.ultimaVoltagem + 'v'">                
                                    <feather-icon icon="CircleIcon" svgClasses="w-3 h-3 fill-current text-warning" class="ml-2"  v-if="tr.ignicao === 'LIGADO' && tr.velocidadeAtual === 0" />
                                </vx-tooltip>
                                <vx-tooltip v-bind:text="'Veículo desligado - voltagem ' + tr.ultimaVoltagem + 'v' ">                
                                    <feather-icon icon="CircleIcon" svgClasses="w-3 h-3 fill-current text-danger" class="ml-2"  v-if="tr.ignicao === 'DESLIGADO'" />
                                </vx-tooltip>
                                <vx-tooltip text="Veículo bloqueado">                
                                    <feather-icon icon="LockIcon" svgClasses="w-3 h-3 stroke-current text-danger" class="ml-2"  v-if="tr.possuiBloqueio && tr.isBloqueado" />
                                </vx-tooltip>
                                <vx-tooltip text="Veículo desbloqueado">                
                                    <feather-icon icon="UnlockIcon" svgClasses="w-3 h-3 stroke-current" class="ml-2" v-if="tr.possuiBloqueio && !tr.isBloqueado" />
                                </vx-tooltip>
                                <vx-tooltip text="Sem alimentação externa">                
                                    <feather-icon icon="ZapOffIcon" svgClasses="w-3 h-3 stroke-current text-danger" class="ml-2"  v-if="tr.semAlimentacaoExterna" />
                                </vx-tooltip>                                  
                            </div>
                            <div class="flex">
                                <vx-tooltip text="Veículo ligado - Ignição Módulo de Backup">                
                                    <feather-icon icon="CircleIcon" svgClasses="w-3 h-3 fill-current text-success" class="ml-2"  v-if="tr.ignicaoBkp === 'LIGADO' && tr.codigoModuloBkp" />
                                </vx-tooltip>
                                <vx-tooltip text="Veículo desligado - Ignição Módulo de Backup">               
                                    <feather-icon icon="CircleIcon" svgClasses="w-3 h-3 fill-current text-danger" class="ml-2"  v-if="tr.ignicaoBkp === 'DESLIGADO' && tr.codigoModuloBkp" />
                                </vx-tooltip>                                 
                            </div>
                        </vs-td>
                        <vs-td class="p-0">
                             <div class="flex">
                                <vx-tooltip text="Possui bloqueio">                
                                    <vs-icon icon="lock" size="x-small" class="ml-1" v-if="tr.possuiBloqueio"></vs-icon>
                                </vx-tooltip>
                                <vx-tooltip text="Possui botão de pânico">                                                    
                                    <vs-icon icon="circle_notifications" size="x-small" class="ml-1" v-if="tr.possuiBotaoPanico"></vs-icon>
                                </vx-tooltip> 
                                <vx-tooltip text="Possui Identificador de Motorista">                
                                    <vs-icon icon="person" size="x-small" class="ml-1" v-if="tr.possuiIdentificadorMotorista"></vs-icon>
                                </vx-tooltip>  
                                <vx-tooltip text="Possui Teclado">                
                                     <vs-icon icon="keyboard" size="x-small" class="ml-1" v-if="tr.possuiTeclado"></vs-icon>
                                </vx-tooltip>  
                                <vx-tooltip text="Possui Sensor Odometro">                
                                    <vs-icon icon="speed" size="x-small" class="ml-1" v-if="tr.possuiSensorOdometro" color="success"></vs-icon>
                                </vx-tooltip>  
                                <vx-tooltip text="Possui Sensor RPM">                
                                    <vs-icon icon="speed" size="x-small" class="ml-1" v-if="tr.possuiSensorRPM" color="danger"></vs-icon>
                                </vx-tooltip>  
                                <vx-tooltip text="Possui Telemetria">                
                                    <vs-icon icon="wifi_tethering" size="x-small" class="ml-1" v-if="tr.possuiTelemetria"></vs-icon>
                                </vx-tooltip>  
                             </div>
                        </vs-td>
                       
                        <vs-td class="p-0" :data="data[indextr].dataAtivacaoEmLong">
                            <vx-tooltip text="Ativação Módulo Principal"> 
                                <small>{{tr.dataAtivacao}}</small>
                            </vx-tooltip>                            
                            <vx-tooltip text="Ativação Módulo Backup"> 
                                <small v-if="tr.codigoModuloBkp">{{tr.dataAtivacaoBkp}}</small>
                            </vx-tooltip>

                        </vs-td>
                        <vs-td class="p-0" :data="data[indextr].dataUltimoPacoteRecebidoLong">
                            <div class="flex">
                                <vx-tooltip :text="'Comunicação atualizada às ' + tr.dataUltimoPacoteRecebido + ' ' + tr.ultimoServidorConectado">                
                                    <feather-icon icon="WifiIcon" svgClasses="w-4 h-4 fill-current text-success" class="mr-2" v-if=" tr.status === 'ON'" />
                                </vx-tooltip>  
                                <vx-tooltip v-bind:text="'Sem transmissão há ' + tr.tempoSemGPRS + ' ' + tr.ultimoServidorConectado" >                
                                    <feather-icon icon="WifiIcon" svgClasses="w-4 h-4 fill-current text-danger" class="mr-2" v-if="tr.status === 'OFF'" />
                                </vx-tooltip>    
                                <small :class="{ 'text-success': (tr.status === 'ON'), 'text-danger': (tr.status === 'OFF')} ">{{tr.dataUltimoPacoteRecebido}}</small>
                            </div>
                            <div class="flex">
                                <vx-tooltip :text="'Comunicação atualizada às ' + tr.dataUltimoPacoteRecebidoBkp + ' ' + tr.ultimoServidorConectadoBkp">                
                                    <feather-icon icon="WifiIcon" svgClasses="w-4 h-4 fill-current text-success" class="mr-2" v-if=" tr.statusBkp === 'ON' && tr.codigoModuloBkp" />
                                </vx-tooltip>  
                                <vx-tooltip v-bind:text="'Sem transmissão há ' + tr.tempoSemGPRSBkp + ' ' + tr.ultimoServidorConectadoBkp" >                
                                    <feather-icon icon="WifiIcon" svgClasses="w-4 h-4 fill-current text-danger" class="mr-2" v-if="tr.statusBkp === 'OFF' && tr.codigoModuloBkp" />
                                </vx-tooltip>    
                                <small v-if="tr.codigoModuloBkp" :class="{ 'text-success': (tr.statusBkp === 'ON'), 'text-danger': (tr.statusBkp === 'OFF')} ">{{tr.dataUltimoPacoteRecebidoBkp}}</small>
                            </div>
                            <vx-tooltip text="Última atualização (velocidade, ignição e/ou latitude/longitude)" >                               
                                    <feather-icon icon="ActivityIcon" svgClasses="w-4 h-4 fill-current" class="mr-2" />
                                    <small>{{tr.dataUltimaAtualizacao}}</small>
                            </vx-tooltip>
                        </vs-td>
                        <vs-td class="p-0">
                            <small>{{tr.dadosGPS}}</small>
                            <br v-if="tr.codigoModuloBkp"/>
                            <small v-if="tr.codigoModuloBkp">{{tr.dadosGPSBkp}}</small>
                        </vs-td>
                        <vs-td class="p-0">
                            <small>{{formatarNumero3Decimais(tr.ultimoOdometro/1000.0)}}</small>
                            <br v-if="tr.codigoModuloBkp"/>
                            <small v-if="tr.codigoModuloBkp">{{formatarNumero3Decimais(tr.ultimoOdometroBkp/1000.0)}}</small>
                        </vs-td>
                        <vs-td class="p-0">
                            <small>{{formatarNumero2Decimais(tr.ultimoHorimetro/60)}}</small>
                            <br v-if="tr.codigoModuloBkp"/>
                            <small v-if="tr.codigoModuloBkp">{{formatarNumero2Decimais(tr.ultimoHorimetroBkp/60)}}</small>
                        </vs-td>
                        <vs-td class="p-0"><small>{{tr.ultimoRPM}}</small></vs-td>
                        <vs-td class="p-0">
                            <div class="flex">
                                <vx-tooltip text="Enviar Comandos">   
                                    <feather-icon icon="SendIcon" svgClasses="w-4 h-4 stroke-current text-primary" class="mr-2" @click="abrirPopUpEnviarComandos(tr)"/>
                                </vx-tooltip>
                                 <vx-tooltip text="Clique para bloquear o veículo">   
                                    <feather-icon icon="LockIcon" svgClasses="w-4 h-4 stroke-current text-danger" class="mr-2" v-if="tr.possuiBloqueio && !isSuporteInterno" @click="bloquear(tr)"/>
                                </vx-tooltip>
                                <vx-tooltip text="Clique para desbloquear o veículo">   
                                    <feather-icon icon="UnlockIcon" svgClasses="w-4 h-4 stroke-current text-success" class="mr-2" v-if="tr.possuiBloqueio && !isSuporteInterno"  @click="desbloquear(tr)"/>
                                </vx-tooltip>                                
                                <vx-tooltip text="Clique para visualizar o histórico do eventos">   
                                    <feather-icon icon="BellIcon" svgClasses="w-4 h-4 stroke-current text-success" class="mr-2"  @click="abrirPopUpHistoricoEventos(tr)" />
                                </vx-tooltip>     
                                <vx-tooltip text="Clique para visualizar o cobertura operadoras">   
                                    <feather-icon icon="RadioIcon" svgClasses="w-4 h-4 stroke-current text-success"  @click="abrirPopUpCobertura(tr)" />
                                </vx-tooltip>                           
                            </div>
                            <div class="flex">
                                <vx-tooltip text="Clique para visualizar o histórico do panicos">   
                                    <feather-icon icon="AlertCircleIcon" svgClasses="w-4 h-4 stroke-current text-danger" class="mr-2"  @click="abrirPopUpHistoricoPanico(tr)" />
                                </vx-tooltip>
                                <vx-tooltip text="Clique para visualizar o log de erros">   
                                    <feather-icon icon="AlertTriangleIcon" :badge="tr.qtdLogErro" svgClasses="w-4 h-4 stroke-current text-danger" class="mr-2"  @click="abrirPopUpHistoricoErros(tr)" />
                                </vx-tooltip>
                                 <vx-tooltip text="Clique para visualizar o log de comunicação">   
                                    <feather-icon icon="ActivityIcon" svgClasses="w-4 h-4 stroke-current text-primary" class="mr-2" @click="abrirPopUpLogComunicacao(tr)" />
                                </vx-tooltip>
                                <vx-tooltip text="Clique para visualizar os acessórios ativos">   
                                    <feather-icon icon="SearchIcon" svgClasses="w-4 h-4 stroke-current text-primary" class="mr-2" @click="abrirPopUpListaAcessorios(tr)" />
                                </vx-tooltip>
                                <vx-tooltip text="Clique para Listar Rastreamentos">   
                                    <feather-icon icon="SearchIcon" svgClasses="w-4 h-4 stroke-current text-success" class="mr-2" @click="abrirPopUpListarRastreamentos(tr)" />
                                </vx-tooltip>
                                <vx-tooltip text="Abrir Ordem de Serviço">   
                                    <feather-icon icon="FileTextIcon" svgClasses="w-4 h-4 stroke-current text-success" class="mr-2" @click="abriOrdemServico(tr)" />
                                </vx-tooltip>
                                
                            </div>
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
        </vs-col>
    </vs-row>
  </div>
</template>

<script>
import axios from "@/axios.js"
import flatPickr from 'vue-flatpickr-component';
import VueApexCharts from 'vue-apexcharts'
import 'flatpickr/dist/flatpickr.css';
import {Portuguese as PortugueseLocale} from 'flatpickr/dist/l10n/pt.js';
import generico from "@/generico.js"
import EnviarComando from '@/views/components/EnvioComandos'
import CoberturaOperadoras from '@/views/components/CoberturaOperadoras'
import ListarRastreamentos from '@/views/components/ListarRastreamentos'

export default {
    components: {
        flatPickr,
        EnviarComando,
        VueApexCharts,
        CoberturaOperadoras,
        ListarRastreamentos
    },
    created() {
        
    },
    computed: {
        isSuporteInterno(){
            return (this.$store.state.AppActiveUser.perfil === 'EMPRESA_SUPORTE_INTERNO');
        },
        getQtdOnline() {
            let qtd = 0;
            if (this.listaVeiculos && this.listaVeiculos.length > 0) {
                for (var i=0; i < this.listaVeiculos.length; i++) {
                    if (this.listaVeiculos[i].status === 'ON') {
                        qtd++; 
                    }
                }
            }

            return qtd;
        }        
    },
    data() {
        return {
            popUpCobertura: {
                exibir: false,
                dataInicio: null,
                dataFim: null,
                veiculoSelecionado: null,                
                dadosVeiculo: null,
                clienteSelecionado: null
            },
            series: [ ],
            chartOptions: {
                chart: {
                    height: 350,
                    type: 'rangeBar'
                },
                plotOptions: {
                    bar: { horizontal: true }
                },
                dataLabels: {                  
                    enabled: true,
                    formatter: function(val) {
                        var a = generico.zeroPad(new Date(val[0]).getUTCHours(),2) + ":" +  generico.zeroPad(new Date(val[0]).getUTCMinutes(),2)
                        var b = generico.zeroPad(new Date(val[1]).getUTCHours(),2) + ":" +  generico.zeroPad(new Date(val[1]).getMinutes(),2)                        
                        return a + ' - ' + b
                    }
                },
                fill: {
                    type: 'solid',
                    opacity: 0.8
                },
                xaxis: { type: 'datetime', min: null, max: null},
                legend: { position: 'top' }, 
                tooltip: {
                    custom: function(opts) {
                        var a = generico.zeroPad(new Date(opts.y1).getUTCHours(),2) + ":" +  generico.zeroPad(new Date(opts.y1).getUTCMinutes(),2)
                        var b = generico.zeroPad(new Date(opts.y2).getUTCHours(),2) + ":" +  generico.zeroPad(new Date(opts.y2).getUTCMinutes(),2)

                        return ('<b style="color: black"> Horário: ' +  a + ' às ' + b + ' </b> ');
                    }
                }               
            },
            atualizadoAs: null,
            exibirMensagemNenhumDadoEncontrado: false,
            listaClientes: [],
            listaVeiculos: [],
            clienteSelecionado: null,
            dadosDaGridHistorico: [],
            popUpLogComunicacao: {
                exibir: false,
                veiculo: null,
                dataComunicacao: null
            },
            popUpHistoricoEventos: {
                exibir: false,
                veiculo: null,
                dataHoraPanico: null,
                titulo: null,
                tipoHistorico : null,
                possuiModuloBackup: false,
                pesquisarModuloBackup: false
            },
            configDatePicker: {          
                altInput: true,
                altFormat: "d-m-Y",
                dateFormat: "U", 
                altInputClass: "vs-inputx vs-input--input normal hasValue text-center",
                locale: PortugueseLocale
            },
            logComunicacao: [],    
            listaAcessorios: [],         
            popUpAcessorios: {
                exibir: false,
                veiculo: null,
                acessorioSelecionado: null
            },   
            popUpEnviarComando: {
                exibir: false,
                veiculo: null,
            },  
            popUpListarRastreamentos : {
                exibir: false,
                veiculo: null,
            },  
            eventoSelecionado: [],
            publicPath: process.env.BASE_URL
        }
    },
    methods: {
        abrirPopUpCobertura(tr) {            
            this.popUpCobertura.veiculoSelecionado = tr;
            this.popUpCobertura.clienteSelecionado = this.clienteSelecionado;
            this.popUpCobertura.dadosVeiculo = tr.modelo + " " + tr.placa + " " + tr.labelVeiculo;
            if (!this.popUpCobertura.dataInicio) {
                var date = new Date();
                let firstDay = new Date(date.getFullYear(), date.getMonth()-1, 1);
                let lastDay = new Date();
                this.popUpCobertura.dataInicio = firstDay.getFullYear() + "/" + generico.zeroPad(firstDay.getMonth() + 1, 2) + "/" + generico.zeroPad(firstDay.getDate(), 2);
                this.popUpCobertura.dataFim = lastDay.getFullYear() + "/" + generico.zeroPad(lastDay.getMonth() + 1, 2) + "/" + generico.zeroPad(lastDay.getDate(), 2);
            }
            this.popUpCobertura.exibir = true;
        },
        print() {            
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');
            params.append('cpfcnpj', this.clienteSelecionado.CNPJCPF);
            params.append('pdfToPrint', true);
            
            axios.post("/RelatorioClienteComBackup", params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true, responseType: 'arraybuffer' } )
            .then((response) => {
                this.atualizadoAs = generico.formatarTimestampParaDMYHMS(new Date().getTime());
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'Relatorio Cliente com Backup- ' + this.clienteSelecionado.CNPJCPF + '.pdf');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchListaVeiculos - " + error); 
            })    
        },
        abrirPopUpListaAcessorios(tr) {
            this.popUpAcessorios.exibir = true;
            this.popUpAcessorios.veiculo = tr;
            this.fetchListaAcessorios();
        },
        abrirPopUpEnviarComandos(tr) {
            this.popUpEnviarComando.exibir = true;
            this.popUpEnviarComando.veiculo = tr;
        },
        abrirPopUpListarRastreamentos(tr) {
            this.popUpListarRastreamentos.exibir = true;
            this.popUpListarRastreamentos.veiculo = tr;
        },        
        abrirPopUpLogComunicacao(tr) {
            this.popUpLogComunicacao.exibir = true;
            this.popUpLogComunicacao.veiculo = tr;
            this.popUpLogComunicacao.dataComunicacao = String(new Date().getTime() / 1000);
            this.fetchLogComunicacao();
        },
        pesquisarCliente(options, varTermoPesquisa) {
            varTermoPesquisa = varTermoPesquisa.toUpperCase();
            return options.filter(function (c) {
                    return (c.nome.toUpperCase().indexOf(varTermoPesquisa) != -1  || c.razaoSocial.toUpperCase().indexOf(varTermoPesquisa) != -1 || 
                    c.CNPJCPF.toUpperCase().indexOf(varTermoPesquisa) != -1);
            })
        },
        formatarNumero3Decimais(valor) {
            return generico.formatarNumero3Decimais(valor);
        },
        formatarNumero2Decimais(valor) {
            return generico.formatarNumero(valor);
        },
        abrirPopUpHistoricoPanico(tr) {
            this.popUpHistoricoEventos.titulo = "Histórico de Pânicos"
            this.popUpHistoricoEventos.tipoHistorico = 'PANICO';
            this.popUpHistoricoEventos.exibir = true;
            this.popUpHistoricoEventos.veiculo = tr;
            this.popUpHistoricoEventos.dataHoraPanico = String(new Date().getTime() / 1000);
            this.popUpHistoricoEventos.possuiModuloBackup = false;// Pesquisa feita pelo id do veículo;
            this.popUpHistoricoEventos.pesquisarModuloBackup = false;
            this.fetchHistoricoPanicos();
        },
        abrirPopUpHistoricoEventos(tr) {
            this.popUpHistoricoEventos.titulo = "Histórico de Eventos"
            this.popUpHistoricoEventos.tipoHistorico = 'EVENTO';
            this.popUpHistoricoEventos.exibir = true;
            this.popUpHistoricoEventos.veiculo = tr;
            this.popUpHistoricoEventos.dataHoraPanico = String(new Date().getTime() / 1000);
            this.popUpHistoricoEventos.possuiModuloBackup = false;// Pesquisa feita pelo id do veículo;
            this.popUpHistoricoEventos.pesquisarModuloBackup = false;
            this.fetchHistoricoEventos();
        },
        abrirPopUpHistoricoErros(tr) {
            this.popUpHistoricoEventos.titulo = "Log de Erros"
            this.popUpHistoricoEventos.tipoHistorico = 'ERROS';
            this.popUpHistoricoEventos.exibir = true;
            this.popUpHistoricoEventos.veiculo = tr;
            this.popUpHistoricoEventos.dataHoraPanico = String(new Date().getTime() / 1000);
            this.popUpHistoricoEventos.possuiModuloBackup = tr.hasOwnProperty('codigoModuloBkp');
            this.popUpHistoricoEventos.pesquisarModuloBackup = false;
            this.fetchHistoricoErros();
        },
        fetchListaVeiculos() {     
            if (!this.clienteSelecionado || !this.clienteSelecionado.id) {
                this.listaVeiculos = null;
                this.listaVeiculos = [];
                this.atualizadoAs = null; 
                return;
            }
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');
            params.append('cpfcnpj', this.clienteSelecionado.CNPJCPF);
            
            axios.post("/RelatorioClienteComBackup", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.atualizadoAs = generico.formatarTimestampParaDMYHMS(new Date().getTime());
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.listaVeiculos = response.data.listaVeiculos;  
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchListaVeiculos - " + error); 
            })    
                                
        },
        fetchListaClientes() {     
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('method', 'FindAll');
            params.append('orderDirection', 'ASC');
            params.append('outputFormat', 'JSON');
            params.append('naoExibirInativos', 'true');

            if (this.$store.state.AppActiveUser.perfil === "MONITOR_PANICO" || this.$store.state.AppActiveUser.perfil === "EMPRESA_MONITOR_PANICO") {
                params.append('monitoravelPorCentral', 'true');
            }
            
            axios.post("/ClientesRest", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.listaClientes = response.data;         
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
            })    
                                
        }, 
        bloquear(tr) {         
        this.eventoSelecionado =tr;   
            this.$vs.dialog({
                type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja bloquear o veículo ' +  tr.placa + ' ? ', acceptText: 'Sim', cancelText: 'Não', accept: this.bloquearRequest
            });      

        },
        bloquearRequest() {
            this.enviarBloqueioDesbloqueio(this.eventoSelecionado, 'BLOQUEAR');
        },
        desbloquear(tr) {            
            this.eventoSelecionado = tr;   
            this.$vs.dialog({
                type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja desbloquear o veículo ' +  tr.placa + ' ? ', acceptText: 'Sim', cancelText: 'Não', accept: this.desbloquearRequest
            });            
        },
        desbloquearRequest() {
            this.enviarBloqueioDesbloqueio(this.eventoSelecionado, 'DESBLOQUEAR');
        },
        enviarBloqueioDesbloqueio(evento, acao) {
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');

            params.append('serialModulo', evento.serialModulo);
            params.append('operadoraGSM', evento.operadoraGSM);
            params.append('codigoModulo', evento.codigoModulo);
            params.append('marcaModulo', evento.marcaModulo);
            params.append('tipoComandoBloqueioDesbloqueio', acao);
            params.append('idUsuario', this.$store.state.AppActiveUser.id);

            axios.post("/EnviarComandoBloqueioDesbloqueio", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                if (this.popMonitoramento) {
                    this.popMonitoramento.exibir = false;
                }
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    if (response.data.statusCodigo === 0) {
                    this.$vs.notify({
                        title: "Mensagem", text: response.data.mensagem,
                        iconPack: 'feather', icon: 'icon-info-circle', color: 'success' });
                    }  else {
                    this.$vs.notify({
                        title: "Mensagem", text: response.data.mensagem,
                        iconPack: 'feather', icon: 'icon-alert-circle', color: 'danger' });
                    }              
                }
            })
            .catch((error) => { 
                if (this.popMonitoramento) {
                    this.popMonitoramento.exibir = false;
                }
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchListaPoI - " + error); 
            })  
        },
        fetchHistorico(){
            if (this.popUpHistoricoEventos.tipoHistorico === 'PANICO') {
                this.fetchHistoricoPanicos();
            } else if (this.popUpHistoricoEventos.tipoHistorico === 'EVENTO') {
                this.fetchHistoricoEventos();
            } else if (this.popUpHistoricoEventos.tipoHistorico === 'ERROS') {
                this.fetchHistoricoErros();
            }
        },
        fetchHistoricoPanicos() {     
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');
            params.append('idVeiculo', this.popUpHistoricoEventos.veiculo.idVeiculo);
            if (this.popUpHistoricoEventos.dataHoraPanico) {
                params.append('dataHoraPanico', this.popUpHistoricoEventos.dataHoraPanico*1000);
            }

            axios.post("/ListarHistoricoPanicoVeiculo", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.dadosDaGridHistorico = response.data;         
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
            })    
                                
        },
        fetchHistoricoEventos() {     
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');
            params.append('idVeiculo', this.popUpHistoricoEventos.veiculo.idVeiculo);
            if (this.popUpHistoricoEventos.dataHoraPanico) {
                params.append('dataHora', this.popUpHistoricoEventos.dataHoraPanico*1000);
            }

            axios.post("/ListarAvisosVeiculoClientePorData", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.dadosDaGridHistorico = response.data;         
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
            })    
                                
        },
        fetchHistoricoErros() {    
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');
            if (this.popUpHistoricoEventos.possuiModuloBackup && this.popUpHistoricoEventos.pesquisarModuloBackup) {
                params.append('codigoModulo', this.popUpHistoricoEventos.veiculo.codigoModuloBkp);
            } else {
                params.append('codigoModulo', this.popUpHistoricoEventos.veiculo.codigoModulo);
            }
            if (this.popUpHistoricoEventos.dataHoraPanico) {
                params.append('dataHora', this.popUpHistoricoEventos.dataHoraPanico*1000);
            }

            axios.post("/ListarLogErroModulo", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.dadosDaGridHistorico = response.data;         
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
            })    
                                
        },
        fetchLogComunicacao() {     
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('outputFormat', 'JSON');
            params.append('idVeiculo', this.popUpLogComunicacao.veiculo.idVeiculo);
            if (this.popUpLogComunicacao.dataComunicacao) {
                params.append('data', this.popUpLogComunicacao.dataComunicacao*1000);
            }

            axios.post("/ListarHistoricoComunicacaoModulo", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                   this.logComunicacao.splice(0, this.logComunicacao.length);
                   let dados = response.data;

                   let dataFinal = new Date(Date.now());
                   let minimoValor = new Date(Date.now());
                   minimoValor.setUTCHours(0, 0);
                   this.chartOptions.xaxis.min = minimoValor.getTime();

                   let maximoValor = new Date(Date.now());
                   maximoValor.setUTCHours(23, 59);
                   this.chartOptions.xaxis.max = maximoValor.getTime();

                   let itemAnterior = null;
                   let dataInicial = null;
                   let dadosGrafico = [];

                   for(var j=0; j < dados.length; j++) {  
                       this.logComunicacao.push( { hora: dados[j].hora, status: dados[j].status });
                       
                       if (dataInicial === null) {
                           dataInicial = new Date(Date.now());
                           dataInicial.setUTCHours(dados[j].hora.split(":")[0], dados[j].hora.split(":")[1]);
                       }

                       if (itemAnterior  !== null && itemAnterior.status !== dados[j].status) {
                            dataFinal.setUTCHours(dados[j].hora.split(":")[0], dados[j].hora.split(":")[1]);
                            
                            if (itemAnterior.status === 1) {
                                dadosGrafico.push({ x: 'ONLINE', y: [ dataInicial.getTime(), dataFinal.getTime()], fillColor: '#00FF00'});
                            } else {
                                dadosGrafico.push({ x: 'OFFLINE', y: [ dataInicial.getTime(), dataFinal.getTime()], fillColor: '#ff0000'});
                            }

                            dataInicial = new Date(Date.now());
                            dataInicial.setUTCHours(dados[j].hora.split(":")[0], dados[j].hora.split(":")[1]);
                       }

                       itemAnterior = dados[j];
                   }

                   dataFinal.setUTCHours(itemAnterior.hora.split(":")[0], itemAnterior.hora.split(":")[1]);
                   if (itemAnterior.status === 1) {
                       dadosGrafico.push({ x: 'ONLINE', y: [ dataInicial.getTime(), dataFinal.getTime()], fillColor: '#00FF00'});
                   } else {
                       dadosGrafico.push({ x: 'OFFLINE', y: [ dataInicial.getTime(), dataFinal.getTime()], fillColor: '#ff0000'});
                   }

                   this.series.push({data: dadosGrafico});
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
            })    
        },
        fetchListaAcessorios() {     
            this.$vs.loading() 
            const params = new URLSearchParams();
            params.append('method', 'FindAll');
            params.append('outputFormat', 'JSON');
            params.append('idVeiculo', this.popUpAcessorios.veiculo.idVeiculo);

            axios.post("/AcessorioModuloRest", params, {              
                    credentials: 'include',
                    responseEncoding: 'iso88591',
                    withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {
                    this.listaAcessorios = response.data;                          
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchListaPoI - " + error); 
            })    
                                
        },
        abriOrdemServico(tr){
            let _self = this;
            this.$router.push({
                    name: "cadastro-ordem-servico-editar",
                    params: {
                    preDados: {idCliente: _self.clienteSelecionado.id, placa: tr.placa }
                    }
            })
        }
    },
    mounted() {               
        this.fetchListaClientes();
        if (this.$store.getters.filtroRelatorioClienteBackup) {
           this.clienteSelecionado = JSON.parse(JSON.stringify(this.$store.getters.filtroRelatorioClienteBackup));
           this.fetchListaVeiculos();
        }
    },
    beforeDestroy() {    
        this.$store.dispatch('setFiltroRelatorioClienteBackup', this.clienteSelecionado);
    }
}
</script>

<style scoped>
  .tabelaComScroll {      
      overflow: auto;  
      height: calc(100vh - 200px);
      position: relative;      
    } 
    .tabela2ComScroll {      
      overflow: auto;  
      height: calc(100vh - 600px);
      position: relative;      
   } 
</style>

<style lang="scss">  
.con-vs-popup {    
    .vs-popup {
      width: 800px;      
    }
}
</style>